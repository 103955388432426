import React, { useState, useEffect } from "react";
import Loader from "./app/loader/loader";
// firebase user auth
import useFirebaseAuth from "./app/engine/firebase_auth_hook";
// preloader
const halt_loader = false;//this spots the loading and keeps the preloaderon

export default function PreLoader(props) {
    const [comp, setComp] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loaded_app, setLoadedApp] = useState();
    const user = useFirebaseAuth();
    useEffect(() => {
        import("./App.js").then((data) => {
            // when the app is loaded
            // console.log("app loaded");
            if (!halt_loader) {
                setLoadedApp(data)
            }
        }).catch(err => {
            console.error(err)
        })
    }, []);
    // 

    useEffect(() => {
        // componenet update
        if (user !== undefined && loaded_app) {
            // if both app is loaded and firebase sdk is loaded then loading complete
            onLoadingComplete();
        }
    })
    function onLoadingComplete() {
        setLoaded(true);
    }
    function onLoaderAnimationEnd() {
        // rendering app
        setComp(loaded_app);
    }
    // ===================
    if (comp && user !== undefined) {
        // if the component is loaded
        const Comp = comp.default;
        return <Comp />
    } else {
        return (
            <Loader onAnimationEnd={() => { onLoaderAnimationEnd() }} loaded={loaded} />
        )
    }
}