// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAlKQ601uGj_JZfGKlxMEDK44PzdO92tpw",
    authDomain: "creator-angled-in.firebaseapp.com",
    projectId: "creator-angled-in",
    storageBucket: "creator-angled-in.appspot.com",
    messagingSenderId: "762021361491",
    appId: "1:762021361491:web:c712c2c9f6bdfc0ae553a6",
    measurementId: "G-7J9KF24Y7D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// initialized firebase auth
const auth = getAuth(app);

export { app as firebase_app, analytics, auth as firebase_auth }