import React from "react";
import Style from "./loader.module.css";
// styles

const initial_ = {
    width: "100%",
    opacity: 1
}
const final = {
    width: "0%",
    opacity: 0,
    transition: ".5s",
    transitionDelay: ".2s"
}

const spinner_initial = {
    opacity: 1
}
const spinner_final = {
    opacity: 0,
    transition: ".5s"
}

const length = [
    96.16850280761719,
    96.1685791015625,
    295.3382568359375,
    129.43099975585938,
    160.7337188720703,
    245.0869903564453,
    62.466922760009766,
    302.9485778808594,
    194.6800079345703,
    77,
    320.44281005859375,
    129,
    159.86865234375
]
const svgs = [];
// function getDelay(index) {
//     const gap = length.length-1 - index;
//     return `${0.1 * gap}s`
// }
length.forEach((elem, ind) => {
    svgs.push([
        // inital
        {
            strokeDasharray: elem,
            strokeDashoffset: 0
        },
        // final
        {
            strokeDasharray: elem,
            strokeDashoffset: elem,
            transition: "0.5s",
            transitionTimingFunction: "ease-in-out",
            transitionDelay: "0.2s",

        }
    ])
})
// styles end
export default class Loader extends React.Component {
    constructor(props) {
        super(props)
        this.onAnimationEnd = this.onAnimationEnd.bind(this);
    }
    onAnimationEnd() {
        try {
            if (!this.ended) {
                this.props.onAnimationEnd();
                this.ended = true
            }
        } catch (err) {
            console.log(err)
        }
    }
    render() {
        return (
            // ==============================================
            // don't remove html_start and html_end
            //html_start
            <div className={Style.main}>
                <div className={Style.loader_div}>
                    <svg onTransitionEnd={this.onAnimationEnd} width="897" height="151" viewBox="0 0 897 151" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {/* 0 */}
                        {/* added transition end to last element which will animate to it will transtion to home page when all transition it complete */}
                        <path style={this.props.loaded ? svgs[0][1] : svgs[0][0]}
                            d="M70 84.5028L138 16.5" stroke="white" strokeWidth="4" />
                        {/* 1 */}
                        <path style={this.props.loaded ? svgs[1][1] : svgs[1][0]}
                            d="M69 64.5021L137 132.505" stroke="white" strokeWidth="4" />
                        {/* 2 */}
                        <path style={this.props.loaded ? svgs[2][1] : svgs[2][0]}
                            d="M120 30.8993C81.5 -8.10221 13 18.8989 13 74.4011C13 129.903 80.0003 158.904 120 118.903" stroke="#F15B2C" strokeWidth="25" />
                        {/* 3 */}
                        <path style={this.props.loaded ? svgs[3][1] : svgs[3][0]}
                            d="M168.5 21V150.431" stroke="#DC06FF" strokeOpacity="0.86" strokeWidth="25" />
                        {/* 4 */}
                        <path style={this.props.loaded ? svgs[4][1] : svgs[4][0]}
                            d="M169.181 150.5C169.181 88.1482 206.927 34.3231 256.581 34.3231" stroke="#FFA800" strokeOpacity="0.79" strokeWidth="25" />
                        {/* 5 */}
                        <path style={this.props.loaded ? svgs[5][1] : svgs[5][0]}
                            d="M322.979 137.581C312.693 137.719 302.597 134.805 293.968 129.206C285.339 123.607 278.564 115.575 274.5 106.126C270.435 96.6766 269.264 86.2342 271.135 76.1193C273.005 66.0044 277.833 56.6712 285.008 49.3002C292.183 41.9291 301.382 36.8511 311.443 34.7084C321.504 32.5656 331.974 33.4544 341.529 37.2622C351.085 41.07 359.297 47.6259 365.127 56.1008C370.956 64.5758 374.142 74.5891 374.281 84.8746" stroke="#0AFF98" strokeWidth="25" />
                        {/* 6 */}
                        <path style={this.props.loaded ? svgs[6][1] : svgs[6][0]}
                            d="M324.4 87.3391L386.866 87" stroke="white" strokeWidth="4" />
                        {/* 7 */}
                        <path style={this.props.loaded ? svgs[7][1] : svgs[7][0]}
                            d="M475.138 34.21C390.828 34.21 405.582 143.589 492 137.776L492 21" stroke="#00FFFF" strokeWidth="25" />
                        {/* 8 */}
                        <path style={this.props.loaded ? svgs[8][1] : svgs[8][0]}
                            d="M562 21V98.9416C562 151.385 631 150.652 631 98.9416" stroke="#FF5C00" strokeWidth="25" />
                        {/* 9 */}
                        <path style={this.props.loaded ? svgs[9][1] : svgs[9][0]}
                            d="M525 39H602" stroke="white" strokeWidth="4" />
                        {/* 10 */}
                        <path style={this.props.loaded ? svgs[10][1] : svgs[10][0]}
                            d="M772.5 84.5C772.5 91.1974 771.181 97.8293 768.618 104.017C766.055 110.204 762.298 115.827 757.562 120.562C752.827 125.298 747.204 129.055 741.017 131.618C734.829 134.181 728.197 135.5 721.5 135.5C714.803 135.5 708.171 134.181 701.983 131.618C695.796 129.055 690.173 125.298 685.438 120.562C680.702 115.827 676.945 110.204 674.382 104.017C671.819 97.8292 670.5 91.1974 670.5 84.5C670.5 77.8026 671.819 71.1707 674.382 64.9831C676.945 58.7955 680.702 53.1733 685.438 48.4376C690.173 43.7018 695.796 39.9451 701.983 37.3821C708.171 34.8192 714.803 33.5 721.5 33.5C728.197 33.5 734.829 34.8192 741.017 37.3821C747.204 39.9451 752.827 43.7018 757.562 48.4376C762.298 53.1733 766.055 58.7955 768.618 64.9832C771.181 71.1708 772.5 77.8026 772.5 84.5L772.5 84.5Z" stroke="#FF2473" strokeWidth="25" />
                        {/* 11 */}
                        <path style={this.props.loaded ? svgs[11][1] : svgs[11][0]}
                            d="M810.2 21.5V150.5" stroke="#EA8C00" strokeOpacity="0.84" strokeWidth="25" />
                        {/* 12 */}
                        <path style={this.props.loaded ? svgs[12][1] : svgs[12][0]}
                            d="M810.188 150.5C810.188 88.3889 847.642 34.7716 896.913 34.7716" stroke="#FF2473" strokeOpacity="0.77" strokeWidth="25" />
                    </svg>


                </div>
                <span style={this.props.loaded?spinner_final:spinner_initial} className={Style.spinner}></span>
            </div>
            //html_end
            // ==============================================
        )
    }
}