import { useState, useEffect } from "react";

// firebase auth
import { firebase_auth } from "../firebase/init";
import { onAuthStateChanged } from "firebase/auth";
// ============================================
export default function useFirebaseAuth(initialValue) {
    const [value, setValue] = useState(initialValue);

    function handleChange(event) {
        setValue(event.target.value);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebase_auth, (user) => {
            if (user) {
                setValue(user)
            } else {
                setValue(false)
            }
        })
        return () => unsubscribe()
    },[])

    return value
}